import React, { Fragment } from "react";
const Loader = ({ text }) => {
  return (
    <Fragment>
      <div className="loader--container">
        <div className="loader">
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
          <div className="face">
            <div className="circle"></div>
          </div>
          {text && (
            <span className="loader--text">
              <strong>{text}</strong>
            </span>
          )}
        </div>
      </div>
      <style jsx>{`
        .loader--container {
          align-items: center;
          background: #fafafa;
          display: flex;
          height: 100vh;
          justify-content: center;
          z-index: 9;
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
        }
        .loader {
          width: 10em;
          height: 10em;
          font-size: 10px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .loader .face {
          position: absolute;
          border-radius: 50%;
          border-style: solid;
          animation: animate 1s linear infinite;
        }
        .loader .face:nth-child(1) {
          width: 100%;
          height: 100%;
          color: #ff6b26;
          border-color: currentColor transparent transparent currentColor;
          border-width: 0.1em 0.1em 0em 0em;
          --deg: -45deg;
          animation-direction: normal;
        }
        .loader .face:nth-child(2) {
          width: 70%;
          height: 70%;
          color: #4dc13c;
          border-color: currentColor currentColor transparent transparent;
          border-width: 0.1em 0em 0em 0.1em;
          --deg: -135deg;
          animation-direction: reverse;
        }
        .loader .face:nth-child(3) {
          width: 40%;
          height: 40%;
          color: #0166c0;
          border-color: currentColor transparent transparent currentColor;
          border-width: 0.1em 0.1em 0em 0em;
          --deg: -45deg;
          animation-direction: normal;
        }
        .loader .face .circle {
          position: absolute;
          width: 50%;
          height: 0.1em;
          top: 50%;
          left: 50%;
          background-color: transparent;
          transform: rotate(var(--deg));
          transform-origin: left;
        }
        .loader .face .circle::before {
          position: absolute;
          top: -0.25em;
          right: -0.25em;
          content: "";
          width: 0.5em;
          height: 0.5em;
          background-color: currentColor;
          border-radius: 50%;
          box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em,
            0 0 0 0.5em rgba(255, 255, 0, 0.1);
        }
        .loader--text {
          display: flex;
          align-self: flex-end;
          top: 35px;
          position: relative;
        }
        @keyframes animate {
          to {
            transform: rotate(1turn);
          }
        }
      `}</style>
    </Fragment>
  );
};
export default Loader;
