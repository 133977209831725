export const autogestion = require("./autogestion.png");
export const background_logo = require("./background_logo.png");
export const banner_logo = require("./banner_logo.png");
export const calidad = require("./calidad.png");
export const capital = require("./capital.png");
export const compromiso = require("./autogestion.png");
export const diligencia = require("./diligencia.png");
export const home_video = require("./home_video.mp4");
export const honestidad = require("./honestidad.png");
export const innovacion = require("./innovacion.png");
export const mision = require("./mision.png");
export const nivel = require("./nivel.png");
export const positivismo = require("./positivismo.png");
export const proactividad = require("./proactividad.png");
export const responsabilidad = require("./responsabilidad.png");
export const veraz = require("./veraz.png");
export const vision = require("./vision.png");
export const product_security = require("./productos_seguridad.jpg");
export const product_electric = require("./productos_electricidad.jpg");
export const product_concret = require("./productos_cemento.jpg");
export const product_metal = require("./productos_metal.jpg");
export const product_naval = require("./productos_naval.jpg");
export const product_agro = require("./productos_agropecuaria.jpg");
export const service_asesoria = require("./servicio_integral.jpg");
export const service_analisis = require("./servicio_analisis.jpg");
export const service_consultoria = require("./servicio_consultoria.jpg");
export const service_logistica = require("./servicio_logistica.jpg");
export const service_inversion_financiera = require("./servicio_inversion_financiera.jpg");
export const service_inversion_mobiliaria = require("./servicio_inversion_mobiliaria.jpg");
export const whyus_calidad = require("./whyus_calidad.jpg");
export const whyus_atencion = require("./whyus_atencion.jpg");
export const whyus_etica = require("./whyus_etica.jpg");
export const whyus_compromiso = require("./whyus_compromiso.jpg");
export const no_match = require("./no_match.jpg");
export const home_video_thumb = require("./home_thumbnail.jpg");

export const products_images = {
  item_0: product_security,
  item_1: product_electric,
  item_2: product_concret,
  item_3: product_metal,
  item_4: product_naval,
  item_5: product_agro,
};
export const services_images = {
  item_0: service_asesoria,
  item_1: service_analisis,
  item_2: service_consultoria,
  item_3: service_logistica,
  item_4: service_inversion_financiera,
  item_5: service_inversion_mobiliaria,
};
