import React, { useState, useEffect, Fragment, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { banner_logo } from "../assets/images/index";
import { LanguageContext } from "../containers/LanguageContext";
import Loader from "./Loader";

const NavBar = ({ fixture }) => {
  const [menu, handleMenu] = useState("close");
  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(LanguageContext);
  const menuClasses =
    menu === "open"
      ? "navbar--container menu--open"
      : "navbar--container menu--close";
  const location = useHistory();
  useEffect(() => {
    //analytics to location
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const handleMenuOpen = (closeMenu) => {
    closeMenu && handleMenu("close");
  };
  const callLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      location.push("/");
    }, 2000);
  };
  const navbarClasses =
    scroll >= 250 ? "navbar--fixed shadow " : "navbar--fixed ";
  return (
    <Fragment>
      {loading ? (
        <Loader text={state.lang === "ES" ? "Traduciendo" : "Translating"} />
      ) : (
        <div className={navbarClasses}>
          <nav className={menuClasses}>
            <button
              className="menu--toggle"
              onClick={() =>
                menu === "close" ? handleMenu("open") : handleMenu("close")
              }
            ></button>
            <div className="logo--container">
              <img
                title="menu logo"
                src={banner_logo}
                alt="Avatar Solutions Inc Logo"
                loading="lazy"
              />
              <p>
                <span className="orange">Avatar </span>
                <span className="green">Solutions </span>
                <span className="blue">Inc</span>
              </p>
            </div>
            <div className="menu--container">
              <ul className="menu--content">
                {fixture.options.map((item, i) => {
                  return (
                    <li key={"navOption_" + i}>
                      <NavLink
                        exact={i === 0 ? true : false}
                        to={item.link}
                        onClick={() => handleMenuOpen(true)}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="menu--rightside">
              <NavLink
                to={state.lang === "ES" ? "/contacto" : "/contact"}
                onClick={() => handleMenuOpen(false)}
                className="desktop"
              >
                <i className="material-icons">mail_outline</i>
              </NavLink>
              <div className="icon--language" onClick={() => callLoader()}>
                <div
                  className="select--language"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>{state.lang}</span>
                </div>
                <div
                  className="flip--vertical"
                  onClick={() => {
                    state.lang === "ES"
                      ? dispatch({ type: "EN" })
                      : dispatch({ type: "ES" });
                  }}
                >
                  <span>{state.lang === "EN" ? "ES" : "EN"}</span>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}

      <style jsx>
        {`
          .navbar--fixed {
            position: fixed;
            z-index: 3;
            width: 100vw;
            background-color: transparent;
            transition: background-color 0.5s ease;
          }
          .navbar--container {
            display: flex;
            padding: 10px 20px;
            padding-right: 35px;
          }
          .menu--toggle {
            flex-grow: 1;
            background-color: transparent;
            border: none;
            z-index: 4;
            cursor: pointer;
          }
          .menu--toggle:before,
          .menu--toggle:after {
            display: block;
            content: "";
            background: #212121;
            width: 40px;
            height: 1px;
          }
          .menu--toggle:before {
            margin-bottom: 6px;
            transition: transform 0.3s ease;
          }
          .menu--toggle:after {
            margin-top: 6px;
            transition: transform 0.3s ease;
          }
          .menu--toggle:hover:before {
            transform: translateY(-2px);
            transition: all 0.3s ease;
          }
          .menu--toggle:hover:after {
            transform: translateY(2px);
            transition: all 0.3s ease;
          }
          .menu--toggle:active,
          .menu--toggle:focus {
            outline: none;
          }
          .navbar--container.menu--open .menu--toggle:before {
            margin: 0;
            transform: translateY(-3px) rotate(45deg);
          }
          .navbar--container.menu--open .menu--toggle:after {
            margin: 0;
            transform: translateY(-5px) rotate(-45deg);
          }
          .navbar--container.menu--open .menu--container {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.5s ease;
          }
          .navbar--container.menu--open .menu--toggle:before,
          .navbar--container.menu--open .menu--toggle:after {
            background-color: #bdbdbd;
            height: 2px;
          }
          .menu--container {
            opacity: 0;
            top: 0;
            left: 0;
            position: fixed;
            width: 100%;
            height: 100vh;
            margin: 0;
            background-color: #fafafa;
            z-index: 3;
            visibility: hidden;
            padding: 0;
            transition: opacity 0.5s, visibility 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .menu--content {
            padding: 0;
          }
          .menu--content li {
            list-style: none;
            width: 100%;
            text-align: center;
            transition: all 0.8s cubic-bezier(0.2, 0.41, 0.31, 1);
            cursor: pointer;
            margin: 25px 0;
          }
          .menu--content li :global(a) {
            text-decoration: none;
            font-size: 50px;
            color: #757575;
          }
          .menu--content li :global(a.active) {
            border-width: 3px;
            border-style: solid;
            border-image: linear-gradient(to right, #ff3d12, #4dc13c, #0166c0) 0
              0 1 0;
            font-weight: bold;
          }
          .menu--content:hover li {
            filter: blur(4px);
            transform: scale(0.9);
          }
          .menu--container li:hover {
            transition: all 0.8s cubic-bezier(0.2, 0.41, 0.31, 1);
            filter: none;
            transform: scale(1);
          }
          .menu--rightside {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .menu--rightside :global(a) {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            width: 45px;
            height: 45px;
            border-radius: 50px;
            background-color: transparent;
            border: 1px solid #212121;
            opacity: 0.6;
            margin-left: 10px;
          }
          .menu--rightside :global(a i) {
            color: #212121;
            font-size: 17px;
          }
          .menu--rightside :global(a:hover) {
            background: #ffffffad;
            border: 1px solid transparent;
          }
          .menu--rightside .icon--language div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            border-radius: 50px;
            background-color: #ffffffad;
            border: 1px solid transparent;
            opacity: 0.6;
            font-size: 12px;
            font-weight: bold;
            cursor: pointer;
            margin-left: 10px;
          }
          .menu--rightside .icon--language {
            display: flex;
            flex-direction: column;
          }
          .menu--rightside .icon--language div.flip--vertical span {
            transform: rotateX(180deg);
          }
          .menu--rightside .icon--language div.flip--vertical {
            position: absolute;
            visibility: hidden;
            transform: translateY(35px) rotateX(90deg);
            transition: all 0.5s linear;
            border-radius: 50px;
            background-color: #e0e0e0;
            border: 1px solid #212121;
            opacity: 0.6;
          }
          .menu--rightside .icon--language div.flip--vertical:hover {
            background-color: #ffffffad;
            border: 1px solid transparent;
          }
          .menu--rightside .icon--language:hover div.flip--vertical {
            visibility: visible;
            transform: translateY(49px) rotateX(180deg);
            transform-style: preserve-3d;
            transition: all 0.5s ease;
          }
          .navbar--fixed.shadow {
            background-color: #ffffffe0;
            transition: all 0.5s ease;
            box-shadow: -1px -2px 17px black;
          }

          @media screen and (max-width: 992px) {
            .menu--container li :global(a) {
              font-size: 40px;
            }
          }
          @media screen and (max-width: 768px) {
            .menu--container li :global(a) {
              font-size: 25px;
            }
            .logo--container {
              justify-content: center;
            }
          }
          @media screen and (max-width: 576px) {
            .navbar--container {
              padding-right: 15px;
            }
          }
        `}
      </style>
    </Fragment>
  );
};
export default NavBar;
