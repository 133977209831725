import React, { useReducer } from "react";

let reducer = (state, action) => {
  localStorage.setItem("language", action.type);
  return { ...state, lang: action.type };
};

const initialState = {
  lang: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "ES"
};

const LanguageContext = React.createContext(initialState);
function LanguageProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <LanguageContext.Provider value={{ state, dispatch }}>
      {props.children}
    </LanguageContext.Provider>
  );
}
export { LanguageContext, LanguageProvider };
