import React, { lazy, Suspense, useContext, Fragment } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import "./assets/css/main.css";
import "./assets/css/aos.css";
import { LanguageContext } from "./containers/LanguageContext";
import home from "./fixtures/Home.json";
import contact from "./fixtures/Contact.json";
import why_us from "./fixtures/WhyUs.json";
import products_services from "./fixtures/ProductsServices.json";
import header_footer from "./fixtures/HeaderFooter.json";
const Home = lazy(() => import("./containers/Home"));
const Contact = lazy(() => import("./containers/Contact"));
const ProductsServices = lazy(() => import("./containers/ProductsServices"));
const WhyUs = lazy(() => import("./containers/WhyUs"));
const NoMatchPage = lazy(() => import("./containers/NoMatchPage"));

function App() {
  const { state } = useContext(LanguageContext);
  const location = useLocation();
  const appMainClasses =
    location.pathname === "/contacto" ||
    location.pathname === "/contact" ||
    location.pathname === "/productos-y-servicios" ||
    location.pathname === "/products-and-services"
      ? "App notFixed"
      : "App";
  return (
    <Fragment>
      <div className={appMainClasses}>
        <NavBar
          fixture={
            state.lang === "ES"
              ? header_footer.ES.header
              : header_footer.EN.header
          }
        />
        <div className="app--main__content">
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact path={"/"}>
                <Home
                  link={
                    state.lang === "ES"
                      ? header_footer.ES.header
                      : header_footer.EN.header
                  }
                  fixture={state.lang === "ES" ? home.ES : home.EN}
                />
              </Route>
              <Route path={"/(productos-y-servicios|products-and-services)/"}>
                <ProductsServices
                  fixture={
                    state.lang === "ES"
                      ? products_services.ES
                      : products_services.EN
                  }
                />
              </Route>
              <Route path={"/(contacto|contact)/"}>
                <Contact
                  fixture={state.lang === "ES" ? contact.ES : contact.EN}
                />
              </Route>
              <Route path={"/(elegirnos|why-us)/"}>
                <WhyUs fixture={state.lang === "ES" ? why_us.ES : why_us.EN} />
              </Route>
              <Route component={NoMatchPage} />
            </Switch>
          </Suspense>
        </div>
        <Footer
          fixture={
            state.lang === "ES"
              ? header_footer.ES.footer
              : header_footer.EN.footer
          }
        />
      </div>
      <style jsx global>
        {`
          .notFixed .navbar--fixed {
            position: relative;
          }
          .notFixed .app--main__content > div {
            padding-top: 0;
          }
          .notFixed .app--main__content {
            min-height: calc(100vh - 145px);
          }
        `}
      </style>
    </Fragment>
  );
}

export default App;
