import React, { Fragment } from "react";
const Footer = ({ fixture }) => {
  return (
    <Fragment>
      <div className="footer--container border--top__gradient">
        <p>
          {fixture.text}
          <i className="material-icons">copyright</i>
          {fixture.year}
        </p>
      </div>
      <style jxs="true">
        {`
          .footer--container {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 20px;
              background-color: #eeeeee;
          }
          .footer--container i {
              margin: 0 5px;
          }
          .footer--container p {
            display: flex;
            margin: 0;
          }
          @media screen and (max-width: 576px){
            .footer--container p {
              font-size: 12px;
            }
            .footer--container {
              padding: 20px 10px;
            }
          }
          `}
      </style>
    </Fragment>
  );
};
export default Footer;
